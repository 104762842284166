import algos from "../assets/images/certs/algos.jpeg";
import node_dev from "../assets/images/certs/node.png";
import data_dev from "../assets/images/certs/data.png";
import responsive_dev from "../assets/images/certs/responsive.jpeg";
import python_dev from "../assets/images/certs/python.png";
import web_dev from "../assets/images/certs/web_dev.png";

const certificates = [
  {
    id: 1,
    image_path: responsive_dev,
    content: "Responsive Web Design Certtification",
  },
  {
    id: 2,
    image_path: algos,
    content: "FreecodeCamp Algorithms Certtification",
  },
  {
    id: 3,
    image_path: data_dev,
    content: "Data Science Certtification",
  },
  {
    id: 4,
    image_path: node_dev,
    content: "Node.js Developer Certtification",
  },
  {
    id: 5,
    image_path: web_dev,
    content: "Web Developer Certtification",
  },
  {
    id: 6,
    image_path: python_dev,
    content: "Python Certtification",
  },
];

export { certificates };
