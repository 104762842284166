import React from "react";
import {
  School,
  GoReact,
  Python,
  Javascript,
  Health,
  FaNode,
} from "./ExperienceIcons";

export const Training = [
  {
    id: 1,
    title: "Node.js Developer & Team Lead	",
    description:
      "Worked as a Backend Enginner🙂. Responsible for writing API's for all applications. Assigned the role of a PM and Team Leader. Majority of efforts centered on tool selection and enforcing best practices among team mates",
    icon: <FaNode />,
    iconBg: "#f25f4c",
    date: "Aug 2020 - Dec 2021",
    tagline: "work",
    position: "right",
  },
  {
    id: 2,
    title: "Frontend Engineer@HDX",
    description:
      "Wireframed and implemented the UI for almost every project. Model database design for backend. Responsible for mentoring a team of Junior Developers. Utilized React in developing majority of applications",
    icon: <GoReact />,
    iconBg: "#f25f4c",
    date: "Jan 2019 - July 2020",
    tagline: "work",
    position: "left",
  },
  {
    id: 3,
    title: "Zero to Mastery Academy ",
    description:
      "Completed the zero to mastery web developer bootcamp and granted advanced python certification.Moreso,trained in a myriad of technologies ranging from javascript, git,react,nodejs,python, and data science among others. Currently am an alumni",
    icon: <Python />,
    iconBg: "#f25f4c",
    date: "Joined in 2019",
    tagline: "education",
    position: "right",
  },
  {
    id: 4,
    title: "FreeCodeCamp 🔥 ",
    description:
      "Trained in responsive web design, advanced javascript, data structures and algorithms",
    icon: <Javascript />,
    iconBg: "#f25f4c",
    date: "Joined 2018",
    tagline: "education",
    position: "left",
  },
  {
    id: 5,
    title: "Public Health Officer & Data Analyst 🏷️",
    description:
      "Managed vaccine logistics and equipments at Tema General Hospital, Ghana. Drafted an emergency preparedness plan during the recent H1N1 outbreak, and was actively involved in contact tracing during the recent Lassa fever outbreak.  Managed, entered, and analyzed health data at the public health unit",
    icon: <Health />,
    iconBg: "#f25f4c",
    date: "2018 - 2019",
    tagline: "work",
    position: "right",
  },
  {
    id: 6,
    title: "University of Health And Allied Sciences (UHAS) 📚",
    description:
      "Obtained a Bachelor's degree in Public Health with specialty in Disease Control. Education centered on research, health statistics, and epidemiology. Whilst in my final year, I dabbled with PHP and SQL on the side",
    icon: <School />,
    iconBg: "#f25f4c",
    date: "2013 - 2017",
    tagline: "education",
    position: "left",
  },
];
